import { optionGet } from 'common/helpers';
import { oneSecondInMs } from 'config';
import jwt_decode, { JwtPayload } from 'jwt-decode';

import { D, O, pipe } from '@mobily/ts-belt';

export type Token = null | string;

export type AppJwtPayload = JwtPayload & { roles: ReadonlyArray<string> };

const jwtDecodeWithDefaultPayload = (token: string) => jwt_decode<AppJwtPayload>(token);

export const isTokenValid = (token: undefined | null | string): token is string => {
  return pipe(
    O.fromNullable(token),
    O.flatMap(jwtDecodeWithDefaultPayload),
    optionGet('exp'),
    O.flatMap((expTimeInSeconds) => new Date(expTimeInSeconds * oneSecondInMs)),
    O.match(
      (expDate) => expDate > new Date(),
      () => false,
    ),
  );
};

export const getUserId = (token: undefined | null | string) => {
  return pipe(
    token,
    O.flatMap((v) => O.fromExecution(() => jwtDecodeWithDefaultPayload(v))),
    O.flatMap(D.get('sub')),
    O.fromNullable,
    O.toNullable,
  );
};

export const getUserRoles = (token: undefined | null | string) => {
  return pipe(
    token,
    O.flatMap((v) => O.fromExecution(() => jwtDecodeWithDefaultPayload(v))),
    O.flatMap(D.get('roles')),
    O.fromNullable,
    O.toNullable,
  );
};

import { clearOfSpaces, padStart } from 'common/helpers';

import { A } from '@mobily/ts-belt';

import { Lambda } from '../../common/types';
import { GetPropertyConnectionResponse, LinkData, NodeData, SerializedData } from './api';

export const parseConnection = (response: GetPropertyConnectionResponse) => {
  return response.propertyById.connectionByConnectionId;
};

export const getODFs = (serializedData: SerializedData) => {
  return serializedData.nodeDataArray.filter((node) => node.model === 'odf');
};

export const getCrossConnectionPanels = (serializedData: SerializedData) => {
  return serializedData.nodeDataArray.filter((node) => node.model === 'crossPanel');
};

export const getTPOutlets = (serializedData: SerializedData) => {
  return serializedData.nodeDataArray.filter((node) => node.model === 'tpOutlet' && !node.group);
};

export const isPortFree = (
  serializedData: SerializedData,
  node: NodeData,
  portSide: 'left' | 'right',
  portIdx: number,
) => {
  const portArrayKey = `${portSide}Array` as 'leftArray' | 'rightArray';

  const targetPort =
    node[portArrayKey]![portIdx] ??
    node[portArrayKey]!.find((port) => (port as unknown as { portIndex: number }).portIndex === portIdx);

  return !serializedData.linkDataArray.some(
    (link) =>
      (link.from === node.id && link.fromPort === targetPort.portId) ||
      (link.to === node.id && link.toPort === targetPort.portId),
  );
};

export const isOdfPortAvailable = (serializedData: SerializedData, ODFNode: NodeData, port: number) => {
  return isPortFree(serializedData, ODFNode, 'right', port - 1);
};

export const isCrossConnectionPanelPortAvailable = (
  serializedData: SerializedData,
  crossConnectionPanelNode: NodeData,
  port: number,
) => {
  return isPortFree(serializedData, crossConnectionPanelNode, 'right', port - 1);
};
export const getOutletById = (serializedData: SerializedData, outletId: string) => {
  return serializedData.nodeDataArray.find((node) => node.model === 'outlet' && node.id === outletId);
};

export const isTPOutletPortAvailable = (serializedData: SerializedData, tpOutlet: NodeData, port: number) => {
  const isPortTaken = serializedData.linkDataArray
    .filter((link) => link.to === tpOutlet.id)
    .map((link) => clearOfSpaces(link.toPort))
    .includes(`_${clearOfSpaces(padStart(port, 2, '0'))}`);

  const amountOfAvailablePorts = tpOutlet.portCount!;

  return port > 0 && port <= amountOfAvailablePorts && !isPortTaken;
};

export const isTPOutletOutgoingPortAvailable = (serializedData: SerializedData, tpOutlet: NodeData, port: number) => {
  const isPortTaken = serializedData.linkDataArray
    .filter((link) => link.from === tpOutlet.id)
    .map((link) => clearOfSpaces(link.fromPort))
    .includes(`${clearOfSpaces(padStart(port, 2, '0'))}`);

  const amountOfAvailablePorts = tpOutlet.portCount!;

  return port > 0 && port <= amountOfAvailablePorts && !isPortTaken;
};

export const isNodeIncomingPortAvailable = (serializedData: SerializedData, node: NodeData, port: number) => {
  const isPortTaken = serializedData.linkDataArray
    .filter((link) => link.to === node.id)
    .map((link) => clearOfSpaces(link.toPort))
    .includes(`_${clearOfSpaces(padStart(port, 2, '0'))}`);

  const amountOfAvailablePorts = node.portCount!;

  return port > 0 && port <= amountOfAvailablePorts && !isPortTaken;
};

export const getTPOutletById = (serializedData: SerializedData, outletId: string) => {
  if (!serializedData) {
    return null;
  }
  return serializedData.nodeDataArray.find((node) => node.model === 'tpOutlet' && node.id === outletId);
};

export const getNodeById = (serializedData: SerializedData, nodeId: string) => {
  if (!serializedData) {
    return null;
  }
  return serializedData.nodeDataArray.find((node) => node.id === nodeId);
};

export const getTPOutletFibers = (serializedData: SerializedData | undefined, outletId: string) => {
  if (!serializedData) {
    return [];
  }
  return serializedData.linkDataArray.filter((link) => link.to === outletId);
};

export const updateNodeById = (
  serializedData: SerializedData,
  nodeId: string,
  transformFn: Lambda<NodeData, NodeData>,
): SerializedData => {
  const nodeIndex = serializedData.nodeDataArray.findIndex((node) => node.id === nodeId);

  return { ...serializedData, nodeDataArray: A.updateAt(serializedData.nodeDataArray, nodeIndex, transformFn) };
};

export const getDataText = (data: LinkData | NodeData) => data.text;

export const getNodeText = (connectionNode: NodeData) => {
  return connectionNode.text;
};

export const getAllNodesConnectedForNodeId = (serializedData: SerializedData, nodeId: string) => {
  const links = serializedData.linkDataArray.filter((link) => link.from === nodeId || link.to === nodeId);
  return links.map((link) => {
    return (
      { ...getNodeById(serializedData, link.from), port: link.fromPort } || {
        ...getNodeById(serializedData, link.to),
        port: link.toPort,
      }
    );
  });
};

export const createFiber = (
  outletNode: NodeData,
  ODFNode: NodeData,
  fiber: string,
  duct: string,
  length: number,
  db1310: number,
  db1550: number,
) => ({
  id: null,
  odfId: ODFNode.id,
  odfPort: padStart(fiber, 2, '0'),
  outletId: outletNode.id,
  outletPort: `${ODFNode.text} ${padStart(fiber, 2, '0')}`,
  duct,
  length,
  db1310,
  db1550,
  contract: null,
});

export const createTwistedPair = (
  to: NodeData,
  from: NodeData,
  parsedCrossPanelPort: string,
  parsedTPOutletPort: string,
  length: number,
  category: string,
  shielding: string,
) => ({
  id: null,
  from: {
    portId: `${padStart(parsedCrossPanelPort, 2, '0')}`,
    nodeId: from.id,
  },
  to: {
    portId: `_${padStart(parsedTPOutletPort, 2, '0')}`,
    nodeId: to.id,
  },
  length,
  category,
  shielding,
});

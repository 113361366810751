import './App.css';

import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useBoolean, useEffectOnce } from 'usehooks-ts';

import { G } from '@mobily/ts-belt';
import { CircularProgress, Grid } from '@mui/material';

import * as styles from './App.styles';
import { QUERY_PERSON_BY_ID, QueryPersonByIdResponse } from './app/authentication/api';
import { useIdbToken } from './app/authentication/hooks/useIdbToken';
import { useAuthenticationState } from './app/authentication/state';
import { isTokenValid } from './app/authentication/Token';
import { useInternationalizationState } from './app/internationalization/state';
import { AppProviders } from './app/providers/AppProviders';
import { AppRoutes } from './app/routes/components/Routes';
import { requestGQL } from './common/graphQlClient';

const AppRaw: FC = () => {
  const { changeToken, userId } = useAuthenticationState();
  const { value: idbToken, isLoading } = useIdbToken();
  const { value: isInitiallyLoaded, setValue: setIsInitiallyLoaded } = useBoolean(false);
  const { changeLanguage } = useInternationalizationState();
  useQuery<QueryPersonByIdResponse, unknown>('personById', () => requestGQL(QUERY_PERSON_BY_ID, { id: userId! }), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: G.isNotNullable(userId),
    onSuccess: (user) => {
      const userLanguage = user.personById.settings?.language;
      if (userLanguage === 'sv' || userLanguage === 'en') {
        changeLanguage(userLanguage);
      }
    },
  });

  useEffectOnce(() => {
    window.caches.keys().then((names) => {
      names.forEach((name) => {
        window.caches.delete(name);
      });
    });
  });

  useEffect(() => {
    if (isTokenValid(idbToken)) {
      changeToken(idbToken);
    }
  }, [idbToken]);

  useEffect(() => {
    if (!isLoading) {
      setIsInitiallyLoaded(true);
    }
  }, [isLoading]);

  return (
    <>
      {!isInitiallyLoaded && (
        <Grid container sx={styles.loaderContainer}>
          <CircularProgress />
        </Grid>
      )}
      {isInitiallyLoaded && <AppRoutes />}
    </>
  );
};

export const App: FC = () => {
  return (
    <AppProviders>
      <AppRaw />
    </AppProviders>
  );
};

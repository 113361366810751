import {
  createTwistedPair,
  getDataText,
  getNodeText,
  getTPOutletById,
  isCrossConnectionPanelPortAvailable,
  isTPOutletPortAvailable,
} from 'app/connection/Connection';
import { useConnection } from 'app/connection/hooks/useConnection';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import * as R from 'rambda';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { A, D, G, O, pipe } from '@mobily/ts-belt';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { TPCategory } from 'app/fibers/TPCategory';
import { TPShielding } from 'app/fibers/TPShielding';
import { useCreateTwistedPair } from '../../useCreateTwistedPair';
import { styles } from '../AddFiber.styles';
import { TpOutletDetails } from '../../../areas/types/ObjectDetails';
import { usePropertyContext } from '../../../properties/components/SingleProperty';
import { AddFiberTPOutletOutsidePremiseSchema } from '../../addFiberTPOutletOutsidePremiseSchema';

export const AddFiberTPOutletFormOutsidePremise: FC<{
  outletDetails: TpOutletDetails;
  onSuccess?: Lambda<void, void>;
}> = ({ outletDetails, onSuccess }) => {
  const { id: propertyId } = usePropertyContext();

  const { crossConnectionPanelNodes, serialized2, isLoading, isError } = useConnection(propertyId);

  const {
    mutate,
    isLoading: isLoadingUpdateConnection,
    isError: isErrorUpdateConnection,
    isSuccess: isSuccessUpdateConnection,
  } = useCreateTwistedPair();

  const { t } = useTranslation();

  useEffect(() => {
    if (isError || isErrorUpdateConnection) toast.error(t('error.generalMessage'));
  }, [isError, isErrorUpdateConnection]);

  useEffect(() => {
    if (isSuccessUpdateConnection) toast.success(t('general.updatedSuccessfully'));
    if (isSuccessUpdateConnection && G.isNotNullable(onSuccess)) onSuccess();
  }, [isSuccessUpdateConnection]);

  const crossConnectionPanelSelectOptions = useMemo(
    () =>
      pipe(
        O.fromNullable(crossConnectionPanelNodes),
        O.map(R.pipe(A.map(getDataText), A.filter(G.isNotNullable))),
        O.toNullable,
      ),
    [crossConnectionPanelNodes],
  );

  const { control, register, handleSubmit, watch, setValue, trigger } = useForm<AddFiberTPOutletOutsidePremiseSchema>();

  const { errors } = useFormState({ control });

  const getErrorMessage = useErrorMessage(errors);

  const formCrossConnectionPanelValue = watch('crossPanel');

  const pickedCrossConnectionPanelNode = useMemo(
    () =>
      pipe(
        O.fromNullable(crossConnectionPanelNodes),
        O.flatMap(
          A.find((crossConnectionPanel) => getNodeText(crossConnectionPanel) === formCrossConnectionPanelValue),
        ),
        O.toNullable,
      ),
    [crossConnectionPanelNodes, formCrossConnectionPanelValue],
  );

  useEffect(() => {
    pipe(
      O.fromNullable(crossConnectionPanelNodes),
      O.flatMap(A.head),
      O.flatMap((node) => O.fromNullable(getNodeText(node))),
      O.tap((defaultCrossConnectionPanelValue) => setValue('crossPanel', defaultCrossConnectionPanelValue)),
    );
  }, [crossConnectionPanelNodes]);

  useEffect(() => {
    trigger();
  }, [formCrossConnectionPanelValue]);

  const submitFiber = useCallback(
    (formData: AddFiberTPOutletOutsidePremiseSchema) => {
      if (G.isNotNullable(serialized2) && G.isNotNullable(crossConnectionPanelNodes) && D.isEmpty(errors)) {
        const pickedOutlet = getTPOutletById(serialized2, outletDetails.id);
        if (G.isNotNullable(pickedCrossConnectionPanelNode) && G.isNotNullable(pickedOutlet)) {
          const parsedCrossPanelPort = `${formData.crossPanelPort}`.padStart(2, '0');
          const parsedTPOutletPort = `${formData.tpOutletPort}`.padStart(2, '0');
          mutate(
            createTwistedPair(
              pickedOutlet,
              pickedCrossConnectionPanelNode,
              parsedCrossPanelPort,
              parsedTPOutletPort,
              formData.length,
              formData.category,
              formData.shielding,
            ),
          );
        }
      }
    },
    [serialized2, pickedCrossConnectionPanelNode],
  );
  const formRef = useRef(null);
  const connectButton = () => {
    handleSubmit(submitFiber)();
  };
  const tpOutlet = getTPOutletById(serialized2!, outletDetails.id);
  return (
    <Grid container sx={styles.centerContainer}>
      {isLoading && <CircularProgress />}
      {!isLoading && !isError && serialized2 && (
        <Grid container component="form" ref={formRef}>
          <Grid container columnSpacing={1.5} rowSpacing={4}>
            <Grid item xs={12}>
              {crossConnectionPanelSelectOptions && (
                <FormControl fullWidth>
                  <InputLabel htmlFor="crossConnectionPanel">{t('fiber.crossConnectionPanel')}</InputLabel>
                  <NativeSelect
                    {...register('crossPanel', { required: true })}
                    input={<OutlinedInput fullWidth inputProps={{ 'data-testid': 'add-fiber-crossconnectionpanel' }} />}
                    id="crossConnectionPanel"
                  >
                    {crossConnectionPanelSelectOptions.map((crossPanelToPick) => (
                      <option key={crossPanelToPick} value={crossPanelToPick}>
                        {crossPanelToPick}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('crossPanelPort', {
                  valueAsNumber: true,
                  required: true,
                  validate: (crossPanelPort) => {
                    if (
                      G.isNotNullable(pickedCrossConnectionPanelNode) &&
                      !isCrossConnectionPanelPortAvailable(serialized2, pickedCrossConnectionPanelNode, crossPanelPort)
                    ) {
                      return t('fiber.add.error.invalidCrossConnectionPanelPort');
                    }
                    return true;
                  },
                })}
                inputProps={{
                  'data-testid': 'add-fiber-fiber',
                }}
                type="number"
                fullWidth
                label={t('fiber.crossPanelPort')}
                variant="outlined"
                helperText={getErrorMessage('crossPanelPort')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('tpOutletPort', {
                  valueAsNumber: true,
                  required: true,
                  validate: (tpOutletPort) => {
                    if (G.isNotNullable(tpOutlet) && !isTPOutletPortAvailable(serialized2, tpOutlet, tpOutletPort)) {
                      return t('fiber.add.error.tpOutletPort');
                    }
                    return true;
                  },
                })}
                inputProps={{
                  'data-testid': 'add-fiber-fiber',
                }}
                type="number"
                fullWidth
                label={t('fiber.tpOutletPort')}
                variant="outlined"
                helperText={getErrorMessage('tpOutletPort')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('category', { required: true })}
                select
                fullWidth
                label={t('fiber.category')}
                variant="outlined"
                helperText={getErrorMessage('category')}
                value={watch('category') || ''}
              >
                {Object.values(TPCategory).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('shielding', { required: true })}
                select
                fullWidth
                label={t('fiber.shielding')}
                variant="outlined"
                helperText={getErrorMessage('shielding')}
                value={watch('shielding') || ''}
              >
                {Object.values(TPShielding).map((shielding) => (
                  <MenuItem key={shielding} value={shielding}>
                    {shielding}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('length', { valueAsNumber: true })}
                inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-length' }}
                fullWidth
                label={t('tp.length')}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container sx={styles.addFiberSubmitContainer}>
            {isLoadingUpdateConnection && <CircularProgress />}
            {!isLoadingUpdateConnection && !isSuccessUpdateConnection && (
              <Button onClick={connectButton} variant="contained" data-testid="add-fiber-form-save">
                {t('general.save')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

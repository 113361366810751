import create from 'zustand';

import { getUserId, getUserRoles, Token } from './Token';

type AuthenticationState = {
  userId: null | string;
  token: Token;
  roles: ReadonlyArray<string>;
  changeToken: (token: Token) => void;
};

export const useAuthenticationState = create<AuthenticationState>()((set) => ({
  userId: null,
  token: null,
  roles: [],
  changeToken: (token) => set({ token, userId: getUserId(token), roles: getUserRoles(token) ?? [] }),
}));

export const authenticationState = () => useAuthenticationState.getState();
